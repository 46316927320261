import Alpine from "alpinejs";

Alpine.data("navbar", () => {
  return {
    open: false,
    isMobile: false,
    init() {
      console.log("init");
      if (window.innerWidth < 1024) {
        this.isMobile = true;
        this.open = false;
      } else {
        this.isMobile = false;
        this.open = true;
      }
    },
  }
});

const contactForm = () => {
  return {
    form: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    submitted: false,

    submitForm() {
      this.submitted = true;
      console.log("called submit form")
      fetch('http://localhost/contact_form_api', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.form)
      }).then(response => {
        this.submitted = false;
        if (response.statusText == "success") {
          // document.getElementById('event-contact-form').reset();
          Object.assign(this, contactForm());
          alert("Form Submitted Successfully")
        } else {
          alert("Oops, Something went wrong, try again!")
        }
      }).catch(error => {
        alert("oops, Something went wrong, try again!")
        console.error('Error:', error)
      });
    }
  }
}

Alpine.data("contactForm", contactForm);

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function(e) {
    e.preventDefault();

    const targetId = this.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth'
      });
    }
  });
});

window.Alpine = Alpine;
Alpine.start();
